import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Section from './shared/section/sectionWrapper'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import RoundSubmitButton from './shared/buttons/roundSubmitButton'
import Text from './shared/text'
import axios from 'axios'

const ContactUs:FunctionComponent = ()=> {

    const [apiStatus,setApiStatus] = useState(false);
    const [isAlertVisible,setIsAlertVisible] = useState(false);

    const onCloseAlert = React.useCallback(()=> {
        setIsAlertVisible(false);
    },[]);

    const sendEmail = () => {
        const emailDetails = {
            personalizations: [
            {
                to: [
                {
                    email: process.env.CONTACT_US_TO_EMAIL
                }
                ],
                subject: "Contact us request from - " + formik.values.organization
            }
            ],
            from: {
                name: process.env.CONTACT_US_FROM,
                email: process.env.CONTACT_US_FROM_EMAIL
            },
            content: [
            {
                type: "text/html",
                value:  "Full Name : " + formik.values.fullname +
                        "<br> email : " + formik.values.email +  
                        "<br> Organization : " + formik.values.organization + 
                        "<br> Phone/Mobile : " + formik.values.phonenumber + 
                        "<br> Country : " + formik.values.country + 
                        "<br> Message : " + formik.values.query                                            }
            ]
        };          
        axios.post(process.env.CONTACT_US_FUNCTION_API||'' ,emailDetails)
        .then((response) => {
            console.log(response);
          }, (error) => {
            console.log(error);
          }); 

    }

    const formik = useFormik({
        initialValues: {
            fullname:"",
            email:"",
            organization:"",
            phonenumber:"",
            country:"",
            query:"",
            agree:false
        },
        validateOnBlur:true,
        validationSchema: Yup.object().shape({
            fullname:Yup.string().required("fullname required."),
            email:Yup.string().email("invalid email address.").required("email required."),
            //organization:Yup.string().required("organization/institution required."),
            phonenumber:Yup.string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,{message:'incorrect phone number format.'}),
            //.required("phone number required."),
            query: Yup.string().required("query is required."),
            agree:Yup.boolean().required("please agree to continue.")
        }),
        onSubmit:(values)=>{
            if(formik.isValid) {
                console.log("--Calling send email API for contact us functionality --")
                sendEmail();
                console.log(values);
                setApiStatus(true);
                setIsAlertVisible(true);
                formik.resetForm();
            }
        }
    });

    return (
        <Section className="bg-white p-5 px-6 space-y-5 relative">
            <Text className="text-center text-bytestrone-blue lg:text-right whitespace-nowrap" textType="headerL">Take the First Step</Text>
            <div className="flex flex-row  flex-wrap flex-grow ">
                <div className="lg:w-7/12 flex flex-col justify-items-center">
                    {/* <h5 className="md:w-3/4 p-1 text-sm md:text-base mt-5">Reach out to us and find out ways in which we can help you build innovative products.</h5> */}
                    <div className=" w-full flex items-center justify-center"><StaticImage aspectRatio={932/549} className="w-10/12"  src="../images/bytestrone-location-map.png" alt="location map" placeholder="blurred" quality={100} /></div>
                    <div className="flex flex-row items-end  pb-5 md:pb-0">
                        <StaticImage src="../images/location-indicator.png" alt="location" placeholder="blurred" className="w-5 md:w-auto" />
                        <h4 className="font-bold mx-1 md:mx-5 md:text-base text-sm capitalize">Bytestrone India Private Limited, 12F, Tower 2 Trans Asia Cyber Park, Infopark SEZ Phase II,  Kakkanad, Ernakulam Kerala India - 682303</h4>
                    </div>
                </div>

                <div className="lg:w-5/12 lg:pl-10 w-full">
                   
                        <form noValidate className="bg-bytestrone-blue h-full flex flex-col justify-between  md:rounded-md p-5 lg:p-7 md:text-base text-sm space-y-2"  onSubmit={formik.handleSubmit} >
                            <input type="text" maxLength={100} id="fullname" className="rounded-sm p-2 " placeholder="Full Name *" value={formik.values.fullname}  onChange={formik.handleChange}/>
                            {formik.touched.fullname && formik.errors.fullname ?<ErrorMessage message={formik.errors.fullname}/> : null}
                            <input type="email" maxLength={100} id="email" className="rounded-sm p-2" placeholder="Business Email Address *" value={formik.values.email}  onChange={formik.handleChange} />
                            {formik.touched.email && formik.errors.email ?<ErrorMessage message={formik.errors.email}/> : null}
                            <input type="text" maxLength={100} id="organization" className="rounded-sm p-2" placeholder="Organization/Institution " value={formik.values.organization}  onChange={formik.handleChange}/>
                            {formik.touched.organization && formik.errors.organization ?<ErrorMessage message={formik.errors.organization}/> : null}
                            <input type="text" maxLength={18} id="phonenumber" className="rounded-sm p-2" placeholder="Phone/Mobile " value={formik.values.phonenumber}  onChange={formik.handleChange}/>
                            {formik.touched.phonenumber && formik.errors.phonenumber ?<ErrorMessage message={formik.errors.phonenumber}/> : null}
                            <input type="text" id="country" className="rounded-sm p-2" placeholder="Country " value={formik.values.country}  onChange={formik.handleChange}/>
                            {formik.touched.country && formik.errors.country ?<ErrorMessage message={formik.errors.country}/> : null}
                            <div className="flex flex-col items-end">
                                <textarea id="query" maxLength={500} className="rounded-sm p-2 w-full" placeholder="Your Message *" rows={4} cols={50}  value={formik.values.query}  onChange={formik.handleChange}/>
                                <span className="text-white">MAX 500 CHARACTERS ONLY</span>
                            </div>
                            {formik.touched.query && formik.errors.query ?<ErrorMessage message={formik.errors.query}/> : null}
                            <div className="text-white ">
                                {/* <input id="agree" type="checkbox" className="rounded-md h-4 w-4 mr-2" checked={formik.values.agree}  onChange={formik.handleChange}/>
                                <span>{` Yes, I would like Bytestrone to contact me based on the information provided above.`}</span> */}
                                <div>Read our <a className="text-red-600 font-semibold" href="/privacy-policy">Privacy Policy</a> for details on how your information may be used.</div>
                            </div>
                            <RoundSubmitButton disabled={false} value="SUBMIT" className="w-36 md:w-44 cursor-pointer" />
                            {isAlertVisible?<Alert message={apiStatus?"One of our colleagues will get back in touch with you soon!":"Error creating request, please try after some time."} isSuccess={apiStatus} onClose={onCloseAlert} />:null}
                        </form>
                </div>
            </div>
        </Section>   
        
    )
}

const ErrorMessage  :FunctionComponent<{message:string}> = ({message})=> {
    return <div style={{marginTop:'0px',marginBottom:'0px'}} className="text-red-400 text-sm">{message}</div>
}

const Alert:FunctionComponent<{isSuccess:boolean,message:string,onClose?:Function}> = ({isSuccess,message,onClose})=> {
    const className = isSuccess?'border-green-400 text-green-700':"border-red-400 text-red-700";
    const alertContainer = useRef<HTMLDivElement>(null);
    useEffect(()=>{
        const timeout = setTimeout(()=> {
            if(onClose && alertContainer) {
                alertContainer.current?.classList.add("opacity-0");
                onClose();
            }
        },6000);

        return ()=> {
            return clearTimeout(timeout);
        }
    },[]);

    return (
        <div ref={alertContainer} className={`bg-white border  px-4 py-3 rounded relative ${className} transition-opacity duration-1000 ease-in-out`} role="alert">
            <strong className="font-bold">Thank you for getting in touch! </strong>
            <span className="block sm:inline">{message}</span>
            <button className="absolute top-0 bottom-0 right-0 px-4 py-3 focus:outline-none" onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                if(onClose){
                    onClose();
                }
            }}>
                <svg className="fill-current h-6 w-6 text-gray-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </button>
        </div>
    );
}
export default ContactUs;